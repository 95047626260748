const initState = {
  signInError: null,
  signUpError: null,
}

const authReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      return {
        ...state,
        signInError: 'Login failed',
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        signInError: null,
      }
    case 'SIGNOUT_SUCCESS':
      return state
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        signUpError: null,
      }
    case 'SIGNUP_ERROR':
      return {
        ...state,
        signUpError: 'Sign up failed',
      }
    default:
      return state
  }
}

export default authReducer
