import { Offer } from 'types/Offer'

interface InitState {
  fetchedOffersData: Offer[]
}

const initState: InitState = {
  fetchedOffersData: [],
}

export const fetchedOffersDataReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_OFFERS_DATA':
      return {
        fetchedOffersData: [...action.payload],
      }

    default:
      return state
  }
}
