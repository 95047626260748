import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faBriefcase,
  faMapMarkerAlt,
  faClock,
  faStar,
  faCertificate,
  faCheckCircle,
  faTimesCircle,
  faFlag,
  faInfo,
  faUser,
  faClipboardList,
  faPaperPlane,
  faHome,
  faEdit,
  faExternalLinkSquareAlt,
  faTimes,
  faFileContract,
  faDatabase,
  faCheck,
  faGlobe,
  faUsers,
  faTrash,
  faUmbrellaBeach,
  faLongArrowAltRight,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import * as Sentry from '@sentry/react'

import SignedIn from 'layout/SignedIn/SignedIn'
import SignedOut from 'layout/SignedOut/SignedOut'
import GlobalStyle from 'theme/globalStyle'
import { Profile } from 'types/Profile'
import { Auth } from 'types/Auth'
import ScrollToTop from 'effects/ScrollToTop'

library.add(
  fab,
  faBriefcase,
  faMapMarkerAlt,
  faClock,
  faStar,
  faCertificate,
  faCheckCircle,
  faTimesCircle,
  faFlag,
  faInfo,
  faUser,
  faClipboardList,
  faPaperPlane,
  faHome,
  faEdit,
  faExternalLinkSquareAlt,
  faTimes,
  faFileContract,
  faDatabase,
  faCheck,
  faHome,
  faGlobe,
  faUsers,
  faTrash,
  faUmbrellaBeach,
  faLongArrowAltRight,
  faArrowRight
)

interface AppProps {
  auth: Auth
  profile: Profile
}

const App: FC<AppProps> = ({ auth, profile }) => {
  const links = auth.uid ? (
    <SignedIn auth={auth} profile={profile} />
  ) : (
    <SignedOut />
  )

  return (
    <BrowserRouter>
      <GlobalStyle />
      <ScrollToTop />
      {links}
    </BrowserRouter>
  )
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(Sentry.withProfiler(App))
