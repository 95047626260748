import { Worker } from 'types/Worker'

interface InitState {
  fetchedWorkersData: Worker[]
}

const initState: InitState = {
  fetchedWorkersData: [],
}

export const fetchedWorkersDataReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_WORKERS_DATA':
      return { fetchedWorkersData: [...action.payload] }

    default:
      return state
  }
}
