import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
  Container,
  Main,
  DashboardContainer,
  DashboardWrapper,
} from 'defaults/layout.style'
import Loader from 'components/Default/Loader/Loader'
import DefaultRoutes from 'routes/DefaultRoutes/DefaultRoutes'

import { Profile } from 'types/Profile'

import { DashboardRoutesUrls, DefaultRoutesUrls } from 'constants/routes'
import { AccountTypes } from 'constants/accountTypes'

const Navbar = lazy(() => import('components/Default/Navbar/Navbar'))
const Footer = lazy(() => import('components/Website/Footer/Footer'))

const Sidebar = lazy(() => import('components/Dashboard/Sidebar/Sidebar'))
const AdminDashboard = lazy(
  () => import('components/Dashboard/Admin/AdminDashboard/AdminDashboard')
)
const AdminVerifier = lazy(
  () => import('components/Dashboard/Admin/AdminVerifier/AdminVerifier')
)
const AdminCRUD = lazy(
  () => import('components/Dashboard/Admin/AdminCRUD/AdminCRUD')
)
const AdminInfo = lazy(
  () => import('components/Dashboard/Admin/AdminInfo/AdminInfo')
)

export default function AdminSignedIn({ profile }: { profile: Profile }) {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Redirect
          from={DefaultRoutesUrls.LOGIN}
          to={DashboardRoutesUrls.DASHBOARD}
        />
        <Route path={`${DashboardRoutesUrls.DASHBOARD}/:path?`} exact>
          <DashboardContainer>
            <Sidebar profile={profile} accountType={AccountTypes.ADMIN} />
            <DashboardWrapper className="dashboard__wrapper">
              <Route
                exact
                path={DashboardRoutesUrls.DASHBOARD}
                component={() => <AdminDashboard />}
              />
              <Route
                exact
                path={DashboardRoutesUrls.DASHBOARD_ADMIN_VERIFIER}
                component={() => <AdminVerifier />}
              />
              <Route
                exact
                path={DashboardRoutesUrls.DASHBOARD_ADMIN_CRUD}
                component={() => <AdminCRUD />}
              />
              <Route
                exact
                path={DashboardRoutesUrls.DASHBOARD_ACCOUNT}
                component={AdminInfo}
              />
              <Redirect
                from="/dashboard/*"
                to={DashboardRoutesUrls.DASHBOARD}
              />
            </DashboardWrapper>
          </DashboardContainer>
        </Route>
        <Route>
          <Container>
            <Navbar profile={profile} />
            <Main>
              <DefaultRoutes isSignedIn />
            </Main>
            <Footer />
          </Container>
        </Route>
      </Switch>
    </Suspense>
  )
}
