interface InitState {
  offersSeen: string[]
}

const initState: InitState = {
  offersSeen: [],
}

const offersSeenReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'ADD_AN_OFFER':
      return { offersSeen: [...state.offersSeen, action.payload] }

    default:
      return state
  }
}

export default offersSeenReducer
