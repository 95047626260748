export enum DefaultRoutesUrls {
  HOME = '/',
  WHY_US = '/why-us',
  LOGIN = '/login',
}

export enum OfferRoutesUrls {
  OFFER_INDEX = '/offers',
  OFFER_SHOW = '/offer/:id',
}

export enum CompanyRoutesUrls {
  COMPANY_INDEX = '/companies',
  COMPANY_SHOW = '/company/:id',
}

export enum WorkerRoutesUrls {
  WORKER_INDEX = '/workers',
  WORKER_SHOW = '/worker/:id',
}

export enum DashboardRoutesUrls {
  DASHBOARD = '/dashboard',
  DASHBOARD_ACCOUNT = '/dashboard/account',
  DASHBOARD_WORKER_APPLIED = '/dashboard/applied',
  DASHBOARD_COMPANY_POST = '/dashboard/post',
  DASHBOARD_COMPANY_OFFERS = '/dashboard/offers',
  DASHBOARD_ADMIN_VERIFIER = '/dashboard/verifier',
  DASHBOARD_ADMIN_CRUD = '/dashboard/crud',
}
