import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

import authReducer from './authReducer'
import offersSeenReducer from './offersSeenReducer'

import { fetchedOffersDataReducer } from './fetchedOffersDataReducer'
import { fetchedCompaniesDataReducer } from './fetchedCompaniesDataReducer'
import { fetchedWorkersDataReducer } from './fetchedWorkersDataReducer'

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  offersSeen: offersSeenReducer,
  fetchedOffersData: fetchedOffersDataReducer,
  fetchedCompaniesData: fetchedCompaniesDataReducer,
  fetchedWorkersData: fetchedWorkersDataReducer,
})

export default rootReducer
