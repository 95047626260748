import { Company } from 'types/Company'

interface InitState {
  fetchedCompaniesData: Company[]
}

const initState: InitState = {
  fetchedCompaniesData: [],
}

export const fetchedCompaniesDataReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_COMPANIES_DATA':
      return { fetchedCompaniesData: [...action.payload] }

    default:
      return state
  }
}
