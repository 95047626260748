import ClipLoader from 'react-spinners/ClipLoader'

import { PRIMARY_RED } from 'constants/colors'

import { Container } from './Loader.style'

export default function Loader() {
  return (
    <Container>
      <ClipLoader size={100} color={PRIMARY_RED} loading={true} />
    </Container>
  )
}
