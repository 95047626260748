import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
  Container,
  Main,
  DashboardContainer,
  DashboardWrapper,
} from 'defaults/layout.style'
import Loader from 'components/Default/Loader/Loader'
import DefaultRoutes from 'routes/DefaultRoutes/DefaultRoutes'

import { FirestoreCollections } from 'constants/firestoreCollections'
import { DashboardRoutesUrls, DefaultRoutesUrls } from 'constants/routes'

import { Profile } from 'types/Profile'

const Navbar = lazy(() => import('components/Default/Navbar/Navbar'))
const Footer = lazy(() => import('components/Website/Footer/Footer'))

const Sidebar = lazy(() => import('components/Dashboard/Sidebar/Sidebar'))
const WorkerInfo = lazy(
  () => import('components/Dashboard/Worker/WorkerInfo/WorkerInfo')
)
const WorkerDashboard = lazy(
  () => import('components/Dashboard/Worker/WorkerDashboard/WorkerDashboard')
)
const WorkerApplied = lazy(
  () => import('components/Dashboard/Worker/WorkerApplied/WorkerApplied')
)

export default function WorkerSignedIn({ profile }: { profile: Profile }) {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Redirect
          from={DefaultRoutesUrls.LOGIN}
          to={DashboardRoutesUrls.DASHBOARD}
        />
        <Route path={`${DashboardRoutesUrls.DASHBOARD}/:path?`} exact>
          <DashboardContainer>
            <Sidebar
              profile={profile}
              accountType={FirestoreCollections.WORKERS}
            />
            <DashboardWrapper className="dashboard__wrapper">
              <Route
                exact
                path={DashboardRoutesUrls.DASHBOARD}
                component={() => <WorkerDashboard profile={profile} />}
              />
              <Route
                exact
                path={DashboardRoutesUrls.DASHBOARD_WORKER_APPLIED}
                component={() => <WorkerApplied profile={profile} />}
              />
              <Route
                exact
                path={DashboardRoutesUrls.DASHBOARD_ACCOUNT}
                component={WorkerInfo}
              />
              <Redirect
                from="/dashboard/*"
                to={DashboardRoutesUrls.DASHBOARD}
              />
            </DashboardWrapper>
          </DashboardContainer>
        </Route>
        <Route>
          <Container>
            <Navbar profile={profile} />
            <Main>
              <DefaultRoutes isSignedIn />
            </Main>
            <Footer />
          </Container>
        </Route>
      </Switch>
    </Suspense>
  )
}
