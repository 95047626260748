import React, { FC, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { rrfProps, store, persistor } from 'store/store'
import Loader from 'components/Default/Loader/Loader'
import { useAppSelector } from 'helpers/redux-hooks'

import reportWebVitals from './reportWebVitals'
import App from './App/App'

Sentry.init({
  dsn:
    'https://15083d398e7a48569180bcd7420770ff@o1142455.ingest.sentry.io/6201478',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

interface AuthIsLoadedProps {
  children: ReactNode
}

const AuthIsLoaded: FC<AuthIsLoadedProps> = ({ children }) => {
  const auth = useAppSelector((state: any) => state.firebase.auth)
  if (!isLoaded(auth)) return <Loader />
  return <>{children}</>
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <AuthIsLoaded>
            <App />
          </AuthIsLoaded>
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
