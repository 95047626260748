import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
  Container,
  Main,
  DashboardContainer,
  DashboardWrapper,
} from 'defaults/layout.style'

import Loader from 'components/Default/Loader/Loader'
import DefaultRoutes from 'routes/DefaultRoutes/DefaultRoutes'

import { Profile } from 'types/Profile'
import { Auth } from 'types/Auth'

import { DashboardRoutesUrls, DefaultRoutesUrls } from 'constants/routes'
import { FirestoreCollections } from 'constants/firestoreCollections'

const Navbar = lazy(() => import('components/Default/Navbar/Navbar'))
const Footer = lazy(() => import('components/Website/Footer/Footer'))

const Sidebar = lazy(() => import('components/Dashboard/Sidebar/Sidebar'))
const CompanyDashboard = lazy(
  () => import('components/Dashboard/Company/CompanyDashboard/CompanyDashboard')
)
const CompanyPost = lazy(
  () => import('components/Dashboard/Company/CompanyPost/CompanyPost')
)
const CompanyOffers = lazy(
  () => import('components/Dashboard/Company/CompanyOffers/CompanyOffers')
)
const CompanyInfo = lazy(
  () => import('components/Dashboard/Company/CompanyInfo/CompanyInfo')
)

export default function CompanySignedIn({
  profile,
  auth,
}: {
  profile: Profile
  auth: Auth
}) {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Redirect
          from={DefaultRoutesUrls.LOGIN}
          to={DashboardRoutesUrls.DASHBOARD}
        />
        <Route path={`${DashboardRoutesUrls.DASHBOARD}/:path?`} exact>
          <DashboardContainer>
            <Sidebar
              profile={profile}
              accountType={FirestoreCollections.COMPANIES}
            />
            <DashboardWrapper className="dashboard__wrapper">
              <Route
                exact
                path={DashboardRoutesUrls.DASHBOARD}
                component={() => (
                  <CompanyDashboard profile={profile} auth={auth} />
                )}
              />
              <Route
                path={DashboardRoutesUrls.DASHBOARD_COMPANY_POST}
                component={() => <CompanyPost profile={profile} />}
              />
              <Route
                path={DashboardRoutesUrls.DASHBOARD_COMPANY_OFFERS}
                component={() => <CompanyOffers profile={profile} />}
              />
              <Route
                path={DashboardRoutesUrls.DASHBOARD_ACCOUNT}
                component={CompanyInfo}
              />
              <Redirect
                from="/dashboard/*"
                to={DashboardRoutesUrls.DASHBOARD}
              />
            </DashboardWrapper>
          </DashboardContainer>
        </Route>
        <Route>
          <Container>
            <Navbar profile={profile} />
            <Main>
              <DefaultRoutes isSignedIn />
            </Main>
            <Footer />
          </Container>
        </Route>
      </Switch>
    </Suspense>
  )
}
