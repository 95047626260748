import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import {
  createFirestoreInstance,
  getFirestore,
  reduxFirestore,
} from 'redux-firestore'
import { getFirebase } from 'react-redux-firebase'

import rootReducer from 'store/reducers/rootReducer'
import firebase from 'config/firebaseConfig'
import firebaseConfig from 'config/firebaseConfig'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['offersSeen'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(firebaseConfig as any)
  )
)

export const persistor = persistStore(store)

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
}

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
