import { createGlobalStyle } from 'styled-components'

import { PRIMARY_RED } from 'constants/colors'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Poppins', sans-serif;
    width: 100vw;
    font-size: 0.875rem;
    background: #FAFAFA;
    overflow-x: hidden;
  }

  ::selection {
    background: ${PRIMARY_RED};
    color: white;
  }

  body::-webkit-scrollbar {
    width: 0.5em;
  }

  body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background: ${PRIMARY_RED};
    outline: 1px solid white;
    border-radius: 1em;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h2, h3 {
    font-weight: 500;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  .container {
    width: 1200px;
  }

  .colored {
    color: ${PRIMARY_RED};
  }

  .btn {
    padding: 0.625rem;
    border-radius: 0.25rem;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    cursor: pointer;
  }

  .btn-primary {
    background: ${PRIMARY_RED};
    color: #fff !important;
    border: 0.125rem solid ${PRIMARY_RED};
    transition: .2s ease-in-out;

    &:hover {
      transition: .2s ease-in-out;
      background: transparent;
      color: ${PRIMARY_RED} !important;
    }
  }

  .btn-secondary {
    background: #fff;
    color: ${PRIMARY_RED} !important;
    border: 0.125rem solid ${PRIMARY_RED};
    transition: .2s ease-in-out;

    &:hover {
      transition: .2s ease-in-out;
      background: ${PRIMARY_RED};
      color: #fff !important;
    }
  }

  .input-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.625rem;

    textarea {
      height: 200px;
    }
  }

  .icon {
    color: #B6B6B6;
    font-size: 0.625rem;
    margin-right: 0.25rem;
  }

  /* SIDENAV */

  .hamburger {
    display: none;
    position: relative;
    cursor: pointer;
    z-index: 4;
    outline: none;
    background: none;
    border: none;
  }
  .hamburger__wrapper {
    width: 1.5rem;
    height: 1.25rem;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:hover {
      .hamburger--line {
        background-color: ${PRIMARY_RED};
      }
    }
  }
  .hamburger--line {
    position: absolute;
    height: 3px;
    width: 100%;
    transition: all cubic-bezier(0.26, 0.1, 0.27, 1.55) 0.35s;
    background-color: #111;
  }
  .top {
    top: 0%;
    width: 75%;
    left: 0%;
  }
  .middle {
    top: 45%;
    width: 75%;
  }
  .bottom {
    top: 90%;
    width: 75%;
    left: 0%;
  }
  .icon.close .top {
    transform: rotate(45deg);
    top: 48%;
    width: 100%;
  }
  .icon.close .middle,
  .icon.close .bottom {
    transform: rotate(-45deg);
    top: 48%;
    width: 100%;
  }
  .sidenav--open {
    width: 40% !important;
    transition: 0.5s;
  }
  .sidenav {
    width: 0px;
    height: 100%;
    position: fixed;
    top: 0px;
	  right: 0px;
    z-index: 3;
    background-color: #fff;
    transition: 0.2s ease-in-out;
    overflow: hidden;
    box-shadow: 0px 0px 0.625rem rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      margin: 30px 0;
      text-decoration: none;
      font-size: 0.875rem;
      font-weight: 500;
      display: block;
      transition: 0.2s ease-in-out;
      color: inherit;
    }

    .active {
      height: fit-content;
      font-weight: 500;
      color: ${PRIMARY_RED};
    }

    &__more {
      transform: translateX(1rem);
      
      a {
        font-size: 0.875rem;
      }
    }
  }

  .sidenav__content {
    position: relative;
    width: fit-content !important;
  }

  /* SIDENAV END */

  @media screen and (max-width: 1250px) {
    .container {
      width: 95% !important;
    }
    .offer-list, .create-offer-list, .offer-preview-list, .login-grid, .offer-details-list, .dashboard-list, .company-details__list, .worker-details__list, .companyDashboard__wrapper, .workerDetails__list, .company-list, .worker-list, .companyPost__wrapper, .workerDashboard__wrapper {
      grid-template-columns: repeat(1, 1fr) !important;
      grid-gap: 0.25rem !important;
    }
  }

  @media screen and (max-width: 768px) {
    body {
      font-size: 0.875rem !important;
    }
    h1 {
      font-size: 1.5rem !important;
    }
    h2 {
      font-size: 1.25rem !important;
    }
    .navbar-right {
      display: none !important;
    }
    .hamburger {
		  display: block !important;
	  }
    .offer-details-header, .offer-summary-box, .worker-details-header {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
    .offer-details-box {
      align-items: flex-start !important;
    }
    .sidebar__container {
      width: 100% !important;
      height: 6.25rem !important;
      bottom: 0 !important;
      z-index: 2 !important;
      top: auto !important;
    }
    .sidebar__menu-list {
      flex-direction: row !important;
      width: 100% !important;
      justify-content: space-between !important;
      padding: 1.5rem !important;
      height: 100% !important;

      a {
        margin: 0 !important;
      }
    }
    .sidebar__hopp-logo, .sidebar__bottom {
      display: none !important;
    }
    .dashboard__wrapper {
      width: 100% !important;
      left: initial !important;
      margin-bottom: 6.25rem !important;

      .container {
        width: 100% !important;
      }
    }
    .dashboard__container {
      padding: 1.5rem !important;
    }
    .dashboard__form {
      width: 99% !important;
    }
  }
`

export default GlobalStyle
