import { FC } from 'react'

import { AccountTypes } from 'constants/accountTypes'

import CompanySignedIn from 'layout/CompanySignedIn/CompanySignedIn'
import WorkerSignedIn from 'layout/WorkerSignedIn/WorkerSignedIn'
import AdminSignedIn from 'layout/AdminSignedIn/AdminSignedIn'

import { Profile } from 'types/Profile'
import { Auth } from 'types/Auth'

interface SignedInProps {
  profile: Profile
  auth: Auth
}

const SignedIn: FC<SignedInProps> = ({ profile, auth }) => {
  const { COMPANY, WORKER, ADMIN } = AccountTypes

  switch (profile.accountType) {
    case COMPANY:
      return <CompanySignedIn profile={profile} auth={auth} />
    case WORKER:
      return <WorkerSignedIn profile={profile} />
    case ADMIN:
      return <AdminSignedIn profile={profile} />
    default:
      return null
  }
}

export default SignedIn
