import { lazy } from 'react'
import { Route } from 'react-router-dom'

import {
  DefaultRoutesUrls,
  OfferRoutesUrls,
  CompanyRoutesUrls,
  WorkerRoutesUrls,
} from 'constants/routes'

const OfferDetailsLive = lazy(
  () => import('components/Default/Offer/OfferDetailsLive/OfferDetailsLive')
)
const CompanyDetailsLive = lazy(
  () =>
    import('components/Website/Company/CompanyDetailsLive/CompanyDetailsLive')
)
const WorkerDetailsLive = lazy(
  () => import('components/Website/Worker/WorkerDetailsLive/WorkerDetailsLive')
)

const Home = lazy(() => import('pages/Home/Home'))
const Offers = lazy(() => import('pages/Offers/Offers'))
const Workers = lazy(() => import('pages/Workers/Workers'))
const Companies = lazy(() => import('pages/Companies/Companies'))
const LogIn = lazy(() => import('pages/LogIn/LogIn'))

const { HOME, LOGIN } = DefaultRoutesUrls
const { OFFER_INDEX, OFFER_SHOW } = OfferRoutesUrls
const { COMPANY_INDEX, COMPANY_SHOW } = CompanyRoutesUrls
const { WORKER_INDEX, WORKER_SHOW } = WorkerRoutesUrls

type DefaultRoutesProps = {
  isSignedIn: boolean
}

const DefaultRoutes = ({ isSignedIn }: DefaultRoutesProps) => (
  <>
    <Route exact path={HOME} component={Home} />
    <Route path={OFFER_INDEX} component={Offers} />
    <Route path={OFFER_SHOW} component={OfferDetailsLive} />
    <Route path={COMPANY_INDEX} component={Companies} />
    <Route path={COMPANY_SHOW} component={CompanyDetailsLive} />
    <Route path={WORKER_INDEX} component={Workers} />
    <Route path={WORKER_SHOW} component={WorkerDetailsLive} />
    {!isSignedIn && <Route path={LOGIN} component={LogIn} />}
  </>
)

export default DefaultRoutes
