import { Suspense, lazy } from 'react'
import { Switch } from 'react-router-dom'

import { Container, Main } from 'defaults/layout.style'
import Loader from 'components/Default/Loader/Loader'
import DefaultRoutes from 'routes/DefaultRoutes/DefaultRoutes'

const TopBar = lazy(() => import('components/Default/TopBar/TopBar'))
const Navbar = lazy(() => import('components/Default/Navbar/Navbar'))
const Footer = lazy(() => import('components/Website/Footer/Footer'))

export default function SignedOut() {
  return (
    <Suspense fallback={<Loader />}>
      <Container>
        <TopBar />
        <Navbar profile={null} />
        <Main>
          <Switch>
            <DefaultRoutes isSignedIn={false} />
          </Switch>
        </Main>
        <Footer />
      </Container>
    </Suspense>
  )
}
